import { WatchCallback, WatchOptions, WatchSource, WatchStopHandle } from '@vue/runtime-core';
import { watch } from 'vue';
import { useRoute } from 'vue-router';

export const rwatch = <T, Immediate extends Readonly<boolean> = false>(routeName: string, source: WatchSource<T>, cb: WatchCallback<T, Immediate extends true ? (T | undefined) : T>, options?: WatchOptions<Immediate>): WatchStopHandle => {
    const route = useRoute();

    return watch(source, (value, oldValue, onInvalidate) => {
        if (routeName !== route.name)
            return;

        return cb(value, oldValue, onInvalidate);
    }, options);
};



