
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid, IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow, IonTextarea, IonTitle, loadingController, modalController
} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import {
  CreateExitDelivery,
  CreateExitDeliveryItem,
  emptyExitDeliveryItemCommand, exitDeliveryItemCommandFromOrder
} from "@/domain/command/delivery/delivery.command";
import {useViewStateApi} from "@/domain/service/api/viewState.api";

export default defineComponent({
  name: "OrderUpdatePage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonTextarea,
    IonHeader,
    IonTitle,
  },
  props: {
    order: null,
    command: null,
  },
  setup(prop: any) {
    const {exitDeliveryBeforeCheck} = useViewStateApi();
    const producedKits = ref<any>(null);
    const scrapAmount = ref<any>(null);
    const notes = ref<any>(null);
    const createButton = ref<any>(null);
    const orderRef = ref<Order>(prop.order);
    const commandProp = ref<CreateExitDelivery>(prop.command);
    const createExitDeliveryItem = ref<CreateExitDeliveryItem>(exitDeliveryItemCommandFromOrder(orderRef.value));
    const scrap = ref<number>(createExitDeliveryItem.value.createProducedOrder.scrap);
    const comments = ref<string>(createExitDeliveryItem.value.createProducedOrder.comments);
    const onNextFocusScrap = async () => {
      scrapAmount.value?.$el.setFocus();
      const element = await scrapAmount.value?.$el.getInputElement();
      setTimeout(() => {
        element.select();
      })
    }
    const onNextFocusNotes = async () => {
      notes.value?.$el.setFocus();
      const element = await notes.value?.$el.getInputElement();
      setTimeout(() => {
        element.select();
      })
    }
    const onCreate = async () => {
      createExitDeliveryItem.value.amount = createExitDeliveryItem.value.createProducedOrder.amount;
      const loading = await loadingController
          .create({
            message: 'Guardando albarán de salida',
            duration: 30000,
          });

      await loading.present();
      createExitDeliveryItem.value.createProducedOrder.comments = comments.value;
      createExitDeliveryItem.value.createProducedOrder.scrap = scrap.value;
      commandProp.value.items.push(createExitDeliveryItem.value);
      await exitDeliveryBeforeCheck(commandProp.value);

      await loading.dismiss();
      await modalController.dismiss();

    };



    watch(createExitDeliveryItem.value.createProducedOrder, () => {
      if (createExitDeliveryItem.value.createProducedOrder.amount < 100)
        // createExitDeliveryItem.value.createProducedOrder.scrap = 1;
        scrap.value = 1;
      else
        // createExitDeliveryItem.value.createProducedOrder.scrap = Math.round(orderRef.value.material.scrapRatio * createExitDeliveryItem.value.createProducedOrder.amount);
          scrap.value =  Math.round(orderRef.value.material.scrapRatio * createExitDeliveryItem.value.createProducedOrder.amount);
    })

    onMounted(() => {
      setTimeout(() => {
        producedKits.value?.$el.setFocus();
      }, 200)
    });

    return {
      comments,
      scrap,
      onNextFocusScrap,
      onNextFocusNotes,
      createButton,
      scrapAmount,
      notes,
      producedKits,
      createExitDeliveryItem,
      orderRef,
      onCreate,
    };
  }
})
