
import {computed, defineComponent, h, ref, watch} from "vue";
import OrderItemListComponent from "@/app/view/order/components/OrderItemListComponent.vue";
import {
  alertController,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonItemDivider,
  IonList,
  IonRow, IonSearchbar, isPlatform
} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import _, {map} from "lodash";
import {useOrderApi} from "@/domain/service/api/order.api";
import {Component, Material} from "@/domain/model/material/material.model";
import {useRouter} from "vue-router";
import {NButton, NDataTable, NSpace, NTag} from "naive-ui";
import {loading} from "@/app/service/ionic/loading";
import {useStock} from "@/domain/service/model/stock.service";
import {useStore} from "vuex";
import {rwatch} from "@/app/service/ionic/reactive";

export default defineComponent({
  name: "OrderListComponent",
  components: {
    IonCard,
    IonCardContent,
    OrderItemListComponent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonButton,
    IonSearchbar,
    IonItemDivider,
    NSpace,
    NDataTable,
  },
  props: {
    orders: null,
    type: null,
  },
  setup(prop: any) {
    const router = useRouter();
    const store = useStore();
    const {finalizeOrderCommit, eliminateOrderCommit, activateOrderCommit} = useOrderApi();
    const {finalizeAllOrdersCommit, activateAllOrdersCommit} = useOrderApi();
    const {calculateAvailableStock, calculateAvailableStockMemoized} = useStock();

    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    const typeProp = ref(prop.type);
    const isOrdersActive = computed(() => typeProp.value.type === 'active');
    const data = ref<any>([]);
    const searchWord = ref<string>('');
    const isSubmitting = ref<boolean>(false);

    const searchOrder = (order: Order, word: string): boolean => order?.material?.ref.toLowerCase().includes(word.toLowerCase()) ||
        order?.material?.name.toLowerCase().includes(word.toLowerCase())
        || order?.material?.group.toLowerCase().includes(word.toLowerCase());
    const filterOrder = (orders: Order[], word: string): Order[] => _.filter(orders, (orderNode: Order) => searchOrder(orderNode, word));
    const sortOrder = (orders: Order[]): Order[] => _.sortBy(orders, (order: Order) => order.material.ref.toLowerCase());

    const ordersList = computed<Order[]>(() => sortOrder(filterOrder(prop.orders, searchWord.value)));
    const allOrders = computed<Order[]>(() => store.getters.allOrders);

    const finalizeAllOrders = async () => {
      if (isSubmitting.value)
        return;
      isSubmitting.value = true;
      await loading().exec('Finalizando ordernes...', async () => await finalizeAllOrdersCommit())
      isSubmitting.value = false;
      await router.push({name: 'OrderRecord'});
    };
    const onFinalizeAll = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Finalización de ordenes',
            message: '¿Estas seguro de que quieres finalizar todas las ordenes de planificación?',
            buttons: [
              {
                text: 'Finalizar todos',
                role: 'success',
                cssClass: 'danger',
                handler: () => {
                  finalizeAllOrders();
                },
              },
              {
                text: 'Atras',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });

      await alert.present();

      // const {role} = await alert.onDidDismiss();
    }

    const activateALlOrders = async () => {
      if (isSubmitting.value)
        return;
      isSubmitting.value = true;
      await loading().exec('Activando ordenes...', async () => await activateAllOrdersCommit());
      isSubmitting.value = false;
    };
    const onActivateALl = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Activando órdenes de planificación',
            message: '¿Estas seguro de que quieres activar todas las órdenes de planificación?',
            buttons: [
              {
                text: 'Activar todos',
                role: 'success',
                cssClass: 'danger',
                handler: () => {
                  activateALlOrders();
                },
              },
              {
                text: 'No activar',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });

      await alert.present();

      // const {role} = await alert.onDidDismiss();

      await router.push({name: 'OrderListPage'});

    };

    const replaceData = () => {
      data.value =
          map(ordersList.value, (orderListNode: Order) => {
            return {
              order: orderListNode,
              material: orderListNode.material,
              id: orderListNode.id,
              ref: orderListNode.material.ref,
              description: orderListNode.material.name,
              group: orderListNode.material.group,
              planning: orderListNode.expectedAmount,
              pendingAmount: orderListNode.pendingAmount,
              state: orderListNode.state,
            };
          });
    };

    const finalizeOrder = async (order: Order) => {
      if (isSubmitting.value)
        return;
      isSubmitting.value = true;
      await loading().exec('Finalizando orden...', async () => finalizeOrderCommit(order));
      isSubmitting.value = false;
    }
    const finalizedOrderEvent = async (order: Order) => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Finalización de orden',
            message: '¿Estas seguro de que quieres finalizar esta orden?',
            buttons: [
              {
                text: 'Finalizar',
                role: 'success',
                cssClass: 'danger',
                handler: () => {
                  finalizeOrder(order);
                },
              },
              {
                text: 'Atras',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });
      await alert.present();

      // const {role} = await alert.onDidDismiss();
    }
    const eliminateOrder = async (orderId: string) => {
      if (isSubmitting.value)
        return;
      isSubmitting.value = true;
      await loading().exec('Elimninar orden...', async () => await eliminateOrderCommit(orderId));
      isSubmitting.value = false;
    }

    const isOrderPossible = (material: Material) => {
      let isNotPossible = false;
      let materialsComponent = material.components;

      if (!materialsComponent)
        materialsComponent = [];

      materialsComponent.forEach((component: Component) => {
        const blockAmountWithApi = calculateAvailableStockMemoized(allOrders.value, component.ref);
        if (blockAmountWithApi < 0)
          isNotPossible = true;
      });
      return !isNotPossible;
    };
    const actualStatus = (material: Material): boolean => isOrderPossible(material);

    const goOrderDetails = (type: any, orderId: string) => {
      router.push({
        name: 'detailsOrder',
        params: {id: orderId, type: type}
      })
    }

    watch(ordersList, () => replaceData(), {immediate: true});
    rwatch('OrderListPage', prop, async (newProp: any) => {
      if(!newProp.orders)
        await loading().show('Cargando...')
      else
        await loading().hide();
    }, {immediate: true, deep: true});

    return {
      data,
      columns: [
        {
          title: "Referencia producto",
          key: "ref",
          sorter: 'default'
        },
        {
          title: "Grupo",
          key: "group",
          sorter: 'default'
        },
        {
          title: "Planificación",
          key: "planning",
          sorter: 'default'
        },
        {
          title: "Cantidad pendiente",
          key: "pendingAmount",
          sorter: 'default'
        },
        {
          title: "Estado",
          key: "state",
          sorter: (row1: any, row2: any) => {
            if (Number(actualStatus(row1.material)) > Number(actualStatus(row2.material)))
              return 1;
            if (Number(actualStatus(row1.material)) < Number(actualStatus(row2.material)))
              return -1;
            else
              return 0;
          },
          render(row: any): any {
            return h(
                NTag,
                {
                  type: actualStatus(row.material) ? 'success' : 'error',
                },
                {default: () => 'Stock'},
            );
          },
        },
        {
          title: 'Acciones',
          key: 'actions',
          render(row: any): any {
            const activeButtons = [{type: 'both', name: 'Detalles'}, {type: 'active', name: 'Finalizar orden'}];
            const pendingButtons = [{type: 'both', name: 'Detalles'}, {type: 'pending', name: 'Eliminar orden'}];
            let buttons = [];
            if (typeProp.value.type === 'active') {
              buttons = activeButtons.map((button) => {
                return h(
                    NButton,
                    {
                      size: 'small',
                      onClick: () => button.name === 'Detalles' ? goOrderDetails(prop.type, row.id) : finalizedOrderEvent(row.order),
                    },
                    {default: () => button.name},
                );
              })
            } else {
              buttons = pendingButtons.map((button) => {
                return h(
                    NButton,
                    {
                      size: 'small',
                      onClick: () => button.name === 'Detalles' ? goOrderDetails(prop.type, row.id) : eliminateOrder(row.id),
                    },
                    {default: () => button.name},
                );
              })
            }
            return buttons;
          },

        },
      ],
      searchWord,
      typeProp,
      onFinalizeAll,
      onActivateALl,
      isOrdersActive,
      ordersList,
      isTablet,
    };
  }
})
