
import {computed, defineComponent, ref} from "vue";
import {
  alertController,
  IonBadge,
  IonButton,
  IonCol, IonGrid,
  IonItem, IonItemDivider, IonItemOption, IonItemOptions, IonItemSliding,
  IonLabel,
  IonRow, isPlatform,
  loadingController,
  modalController
} from "@ionic/vue";
import {Order} from "@/domain/model/order/order.model";
import {useRouter} from "vue-router";
import OrderUpdatePage from "@/app/view/order/update/OrderUpdatePage.vue";
import {useStore} from "vuex";
import {Component} from "@/domain/model/material/material.model";
import {useStock} from "@/domain/service/model/stock.service";
import {useOrderApi} from "@/domain/service/api/order.api";
import {formatDate} from "@/domain/helper/date.helper";

export default defineComponent({
  name: "OrderItemListComponent",
  components: {
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonButton,
    IonBadge,
  },
  props: {
    order: null,
    type: null,
  },
  setup: function (prop: any) {
    const typeProp = ref(prop.type);
    const router = useRouter();
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const {finalizeOrderCommit, eliminateOrderCommit, activateOrderCommit} = useOrderApi();
    const isSubmitting = ref<boolean>(false);
    const {calculateAvailableStock, calculateAvailableStockMemoized} = useStock();
    // const pendingProduction = computed<number>(() => calculatePending(prop.order));
    const pendingProduction = computed<number>(() => {
      if (prop.order.state === 'pending')
        return prop.order.expectedAmount
      else
        return prop.order.pendingAmount;
    });

    const allOrders = computed(() => {
      if (typeProp.value.type === 'active')
        return store.getters.allActiveOrders
      else
        return store.getters.allPendingAndActiveOrders
    });
    const orderBlockedAmounts = computed<boolean>(() => {
      let isAvailable = false;
      let materialsComponent = prop.order.material.components;

      if (!materialsComponent)
        materialsComponent = [];

      materialsComponent.forEach((component: Component) => {
        const blockAmountWithApi = calculateAvailableStockMemoized(allOrders.value, component.ref);
        if (blockAmountWithApi < 0)
          isAvailable = true;
      });

      return isAvailable;
    });

    const onUpdateOrder = async () => {
      const modal = await modalController
          .create({
            component: OrderUpdatePage,
            componentProps: {
              order: prop.order.id
            },
          });
      return modal.present();
    };

    const endDate = formatDate(prop.order.deliveryDate, "DD-MM-YYYY");
    const goOrderDetails = (type: any) => {
      router.push({
        name: 'detailsOrder',
        params: {id: prop.order.id, type: type}
      })
    }

    const activateOrder = async () => {
      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const loading = await loadingController
          .create({
            message: 'Activando Orden',
            duration: 5000,
          });

      await loading.present();
      await activateOrderCommit({...prop.order});

      await loading.dismiss();
      isSubmitting.value = false;
    }
    const eliminateOrder = async () => {
      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const loading = await loadingController
          .create({
            message: 'Eliminando Orden',
            duration: 5000,
          });

      await loading.present();
      await eliminateOrderCommit({...prop.order.id});

      await loading.dismiss();
      isSubmitting.value = false;
    }

    const finalizeOrder = async (order: Order) => {
      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const loading = await loadingController
          .create({
            message: 'Finalizando Orden',
            duration: 5000,
          });

      await loading.present();
      finalizeOrderCommit(order);

      await loading.dismiss();
      isSubmitting.value = false;
    }
    const finalizedOrderEvent = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Finalización de orden',
            message: '¿Estas seguro de que quieres finalizar esta orden?',
            buttons: [
              {
                text: 'Finalizar',
                role: 'success',
                cssClass: 'danger',
                handler: () => {
                  finalizeOrder(prop.order);
                },
              },
              {
                text: 'Atras',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });
      await alert.present();

      const {role} = await alert.onDidDismiss();
    }

    return {
      isTablet,
      activateOrder,
      eliminateOrder,
      typeProp,
      finalizedOrderEvent,
      endDate,
      goOrderDetails,
      onUpdateOrder,
      pendingProduction,
      orderBlockedAmounts,
    };
  }
})
